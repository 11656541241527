<template lang="pug">
  v-container
    v-toolbar(
      color="primary"
      dark
      dense
      elevation="0"
    )
      span Stock Category
      v-spacer
      v-icon mdi-account
    v-data-table(
      dense
      :items="stockCatList"
      :headers="headers"
      :loading="this.stockCatGetting"
      :search="search"
    )
      template(v-slot:top)
        v-row
          v-col(cols="3")
            v-text-field(
              label="search"
              v-model="search"
              append-icon="mdi-magnify"
            )
      template(v-slot:item="{ item }")
        tr
          td {{ item.id }}
          td {{ item.name }}
          td {{ item.revenue_source.code }}
          td {{ item.updated_at.dateFormat({ hour: '2-digit', minute: '2-digit' }) }}
          td
            base-tooltip(
              tooltip="Units"
              text
              bottom
              color="blue-grey darken-3"
              @click="$emit('update:unit', item)"
            )
              v-icon mdi-weight
            v-btn(
              text
              color="yellow darken-2"
              small
              dense
              @click="$emit('input', item)"
            )
              v-icon mdi-pencil
</template>
<script>
import stockCatRepository from '@/repositories/stock-category.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'
import jwtDecode from 'jwt-decode'

const getStockCatDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Revenue Source', value: 'revenue_source_id' },
    { text: 'Update At', value: 'updated_at' },
    { text: 'Config', value: 'config', sortable: false },
  ],
  search: null,
})

export default {
  name: 'CustomerCategoryTable',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    unit: {
      type: Object,
      default: () => ({}),
    },
  },
  created () {
    this.getStockCat()
    this.websocketEvent()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('stockCat'),
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
      token: state => state.auth.token,
    }),
  },
  methods: {
    getStockCat () {
      if (this.stockCatGetting) return
      this.stockCatGetting = true
      this.stockCatGetErrors = []
      const params = { with: 'revenueSource' }
      getStockCatDelay(() => {
        stockCatRepository.index(params)
          .then(({ data }) => { this.stockCatList = data })
          .catch(e => { this.stockCatGetErrors = errorHandler(e) })
          .then(() => { this.stockCatGetting = false })
      })
    },
    websocketEvent () {
      const { echo } = this
      echo.private('database.event')
        .listen('DBStoreEvent', this.dBStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    dbUpdateEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'StockCategory') {
        this.stockCatList = this.stockCatList.map(item => {
          if (item.id === data.id) return Object.assign({ ...item, ...data })
          return Object.assign({ ...item })
        })
      }
    },
    dBStoreEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'StockCategory') {
        this.updateStockCatList(data)
      }
    },
    updateStockCatList (newStockCategory) {
      this.stockCatList = this.stockCatList.objectInsertion(newStockCategory, 'id', 'id')
        .map(item => Object.assign({ ...item }))
    },
  },
}
</script>
